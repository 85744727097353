// document.addEventListener('DOMContentLoaded', () => {
//   if (document.querySelector('.tabs')) {
//     const scope = document.querySelector('.tabs');
//     const tabs = scope.querySelectorAll('.tab-item');
//     const content = document.querySelectorAll('.content-page');
  
//     let activeContent = content[0];
//     let activeTab = tabs[0];
  
//     activeTab.classList.add('--active');
//     activeContent.classList.add('--active');
  
//     tabs.forEach(tab => {
//       tab.addEventListener('click', (e) => {
//         const contentView = document.querySelector(`[data-content="${e.target.dataset.tab}"]`);
  
//         if (contentView && contentView !== activeContent) {
//           activeContent.classList.remove('--active');
//           activeTab.classList.remove('--active');
  
//           contentView.classList.add('--active');
//           tab.classList.add('--active');
  
//           activeContent = contentView;
//           activeTab = tab;
//         }
//       });
//     });
//   }
// });
// Mudança pedida para colocar aba na URL (fora do escopo)
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.tabs')) {
    const scope = document.querySelector('.tabs');
    const tabs = scope.querySelectorAll('.tab-item');
    const content = document.querySelectorAll('.content-page');

    let activeContent = content[0];
    let activeTab = tabs[0];

    const setActiveTab = (tab, contentView) => {
      activeContent.classList.remove('--active');
      activeTab.classList.remove('--active');

      contentView.classList.add('--active');
      tab.classList.add('--active');

      activeContent = contentView;
      activeTab = tab;
    };

    const activateTabFromHash = () => {
      const hash = window.location.hash.replace('#', '');
      const tab = Array.from(tabs).find(tab => tab.dataset.tabName === hash);
      
      if (tab) {
        const contentView = document.querySelector(`[data-content="${tab.dataset.tab}"]`);
        if (contentView) {
          setActiveTab(tab, contentView);
        }
      }
    };

    // Initialize the tabs based on the URL hash
    if (window.location.hash) {
      activateTabFromHash();
    } else {
      activeTab.classList.add('--active');
      activeContent.classList.add('--active');
    }

    tabs.forEach(tab => {
      tab.addEventListener('click', (e) => {
        const contentView = document.querySelector(`[data-content="${e.target.dataset.tab}"]`);

        if (contentView && contentView !== activeContent) {
          setActiveTab(tab, contentView);
          window.location.hash = tab.dataset.tabName; // Atualiza a hash na URL
        }
      });
    });

    // Reativa a aba correta se o usuário voltar para a página com uma hash diferente
    window.addEventListener('hashchange', activateTabFromHash);
  }

  
});

document.addEventListener('DOMContentLoaded', function() {
  const tabs = document.querySelector('.tabs');
  if (!tabs) return;

  let scrollTimeout;
  let isDragging = false;
  let startX;
  let scrollLeft;
  let lastX;
  
  function showScrollbar() {
    tabs.classList.add('is-scrolling');
    
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      if (!isDragging) {
        tabs.classList.remove('is-scrolling');
      }
    }, 1500);
  }

  function hideScrollbar() {
    if (!isDragging) {
      tabs.classList.remove('is-scrolling');
    }
  }

  function handleMouseDown(e) {
    isDragging = true;
    startX = e.pageX;
    lastX = e.pageX;
    scrollLeft = tabs.scrollLeft;
    tabs.style.cursor = 'grabbing';
    showScrollbar(); // Mostra a scrollbar assim que começa o arraste
  }

  function handleMouseMove(e) {
    if (!isDragging) return;
    
    e.preventDefault();
    const currentX = e.pageX;
    const diff = currentX - lastX;
    
    if (diff !== 0) { // Só mostra a scrollbar se houver movimento real
      tabs.scrollLeft = scrollLeft - (currentX - startX);
      showScrollbar();
    }
    
    lastX = currentX;
  }

  function handleMouseUp() {
    if (isDragging) {
      isDragging = false;
      tabs.style.cursor = 'grab';
      
      // Mantem a scrollbar visível por mais 1.5s após soltar
      setTimeout(hideScrollbar, 1500);
    }
  }

  function handleTouchStart(e) {
    isDragging = true;
    startX = e.touches[0].pageX;
    lastX = e.touches[0].pageX;
    scrollLeft = tabs.scrollLeft;
    showScrollbar();
  }

  function handleTouchMove(e) {
    if (!isDragging) return;
    
    const currentX = e.touches[0].pageX;
    const diff = currentX - lastX;
    
    if (diff !== 0) {
      tabs.scrollLeft = scrollLeft - (currentX - startX);
      showScrollbar();
    }
    
    lastX = currentX;
  }

  function handleTouchEnd() {
    if (isDragging) {
      isDragging = false;
      setTimeout(hideScrollbar, 1500);
    }
  }

  // Eventos de mouse
  tabs.addEventListener('mousedown', handleMouseDown);
  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', handleMouseUp);
  document.addEventListener('mouseleave', handleMouseUp);
  
  // Eventos de touch
  tabs.addEventListener('touchstart', handleTouchStart, { passive: true });
  tabs.addEventListener('touchmove', handleTouchMove, { passive: true });
  tabs.addEventListener('touchend', handleTouchEnd);

  // Previne comportamento padrão de drag
  tabs.addEventListener('dragstart', (e) => e.preventDefault());

  // Remove a classe is-scrolling ao clicar em uma tab
  const tabItems = tabs.querySelectorAll('.tab-item');
  tabItems.forEach(tab => {
    tab.addEventListener('click', (e) => {
      if (!isDragging) {
        tabs.classList.remove('is-scrolling');
      }
    });
  });
});