document.addEventListener('DOMContentLoaded', function() {
  const scope = document.querySelector('#page-publi');

  if (scope) {
    const orderContent = scope.querySelector('.order-content');
    const orderItems = scope.querySelectorAll('.order');
  
    // Função de ordenação
    function ordenarPublicacoes(publicacoesArr, orderType) {
      return publicacoesArr.sort((a, b) => {
        const titleA = a.querySelector('.publicacao-title').textContent.trim().toLowerCase();
        const titleB = b.querySelector('.publicacao-title').textContent.trim().toLowerCase();
        const dateA = new Date(a.querySelector('.publicacao-date').textContent.trim());
        const dateB = new Date(b.querySelector('.publicacao-date').textContent.trim());
  
        if (orderType === 'a_z') {
          return titleA.localeCompare(titleB);
        } else if (orderType === 'z_a') {
          return titleB.localeCompare(titleA);
        } else if (orderType === 'mais_recentes') {
          return dateB - dateA;
        } else if (orderType === 'menos_recentes') {
          return dateA - dateB;
        }
      });
    }
  
    // Evento de clique para ordenação
    orderItems.forEach(item => {
      item.addEventListener('click', () => {
        const orderType = item.dataset.order;
        const activePage = scope.querySelector('.content-page.--active'); // Seleciona a página ativa
        const publicacoesList = activePage.querySelector('.publicacoes-list'); // Seleciona a lista de publicações na página ativa
        const publicacoesItens = activePage.querySelectorAll('.publicacao-item');
        let publicacoesArr = [...publicacoesItens]; // Converte NodeList para array
  
        const sortedPublicacoes = ordenarPublicacoes(publicacoesArr, orderType);
  
        // Remove a classe --active de todos os itens de ordenação
        orderItems.forEach(orderItem => orderItem.classList.remove('--active'));
  
        // Adiciona a classe --active ao item clicado
        item.classList.add('--active');
  
        // Limpa a lista e adiciona as publicações ordenadas
        publicacoesList.innerHTML = '';
        sortedPublicacoes.forEach(el => publicacoesList.appendChild(el));
  
        // Fecha a dropdown de ordenação (se aplicável)
        orderContent.classList.remove('--active');
      });
    });
  }
});
