document.addEventListener('DOMContentLoaded', function() {
  const scope = document.querySelector('#o-projeto');

  if (scope) {
    
    const filterItems = document.querySelectorAll('.filter-item');
    const filterAll = document.querySelector('.filter-all');
    const publicacoesList = document.querySelector('#publicacoes-list');
    const publicacoesItens = document.querySelectorAll('.publicacao-item');
    const publicacoesMap = {};

    // Pré-processa as publicações e mapeia-as por slug
    publicacoesItens.forEach(elm => {
      const slug = elm.dataset.frente;
      if (!publicacoesMap[slug]) {
        publicacoesMap[slug] = [];
      }
      publicacoesMap[slug].push(elm);
    });

    // Remove filtros que não possuem publicações associadas
    filterItems.forEach(item => {
      const slug = item.dataset.slug;
      if (slug !== 'all' && (!publicacoesMap[slug] || publicacoesMap[slug].length === 0)) {
        item.style.display = 'none';
      }
    });

    function updatePublicacoesDisplay() {
      publicacoesList.innerHTML = "";

      if (filterAll.classList.contains('--active')) {
        // Se "Todos" está ativo, mostra todas as publicações
        publicacoesItens.forEach(elm => publicacoesList.appendChild(elm));
      } else {
        // Caso contrário, mostra as publicações dos filtros ativos
        let anyFilterMatched = false;
        filterItems.forEach(item => {
          if (item.classList.contains('--active')) {
            const slug = item.dataset.slug;
            if (publicacoesMap[slug]) {
              publicacoesMap[slug].forEach(elm => publicacoesList.appendChild(elm));
              anyFilterMatched = true;
            }
          }
        });
        // Se nenhum filtro corresponder, exibe uma mensagem ou faz algo adicional
        if (!anyFilterMatched) {
          publicacoesList.innerHTML = "<p>Nenhuma publicação encontrada para o filtro selecionado.</p>";
        }
      }
    }

    filterItems.forEach(item => {
      item.addEventListener('click', () => {
        const slug = item.dataset.slug;

        if (slug === 'all') {
          filterAll.classList.add('--active');
          filterItems.forEach(item => item.classList.remove('--active'));
        } else {
          item.classList.toggle('--active');
          filterAll.classList.remove('--active');

          // Se nenhum filter-item estiver ativo, ativa o "Todos"
          const anyFilterActive = [...filterItems].some(item => item.classList.contains('--active'));
          if (!anyFilterActive) {
            filterAll.classList.add('--active');
          }
        }

        updatePublicacoesDisplay();
      });
    });

    if (filterAll) {
      
      filterAll.addEventListener('click', () => {
        filterAll.classList.add('--active');
        filterItems.forEach(item => item.classList.remove('--active'));
        updatePublicacoesDisplay();
      });
  
      // Inicia com "Todos" ativo
      filterAll.classList.add('--active');
      updatePublicacoesDisplay();
    }
  }
});
