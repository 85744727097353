// Adiciona o CSS necessário
const styles = `
<style>
.mobile-lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    touch-action: none;
}

.mobile-lightbox .image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
}

.mobile-lightbox .image-container.png-background {
    background-color: white;
    display: inline-block;
}

.mobile-lightbox img {
    max-width: 100vw;
    max-height: 100vh;
    display: block;
}

.mobile-lightbox .close-btn {
    position: fixed;
    top: 15px;
    right: 15px;
    color: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.zoom-controls {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    z-index: 10000;
}

.zoom-btn {
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
</style>
`;

// Adiciona o CSS ao head
document.head.insertAdjacentHTML('beforeend', styles);

// Adiciona o HTML do lightbox ao body
const lightboxHtml = `
<div class="mobile-lightbox">
    <button class="close-btn">&times;</button>
    <div class="image-container">
        <img src="" alt="Lightbox image">
    </div>
    <div class="zoom-controls">
        <button class="zoom-btn zoom-in">+</button>
        <button class="zoom-btn zoom-out">-</button>
    </div>
</div>
`;
document.body.insertAdjacentHTML('beforeend', lightboxHtml);

// Função principal do lightbox
document.addEventListener('DOMContentLoaded', function() {
    // Verifica se é um dispositivo móvel
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (isMobile) {
        const lightbox = document.querySelector('.mobile-lightbox');
        const imageContainer = lightbox.querySelector('.image-container');
        const lightboxImg = lightbox.querySelector('img');
        let currentScale = 1;
        let startX = 0;
        let startY = 0;
        let moveX = 0;
        let moveY = 0;
        
        // Aplica o lightbox em todas as imagens do conteúdo, exceto as com classe --not-lightbox
        document.querySelectorAll('main img:not(.mobile-lightbox img):not(.--not-lightbox)').forEach(img => {
            img.addEventListener('click', function() {
                const isPNG = this.src.toLowerCase().endsWith('.png');
                imageContainer.classList.toggle('png-background', isPNG);
                
                // Carregar a imagem primeiro para obter dimensões reais
                const tempImg = new Image();
                tempImg.onload = function() {
                    lightboxImg.src = this.src;
                    
                    // Se for PNG, ajustar o container para o tamanho exato da imagem
                    if (isPNG) {
                        const scale = Math.min(
                            window.innerWidth / this.width,
                            window.innerHeight / this.height,
                            1 // Não aumentar imagens pequenas
                        );
                        const width = this.width * scale;
                        const height = this.height * scale;
                        imageContainer.style.width = `${width}px`;
                        imageContainer.style.height = `${height}px`;
                    } else {
                        // Reset para imagens não PNG
                        imageContainer.style.width = '';
                        imageContainer.style.height = '';
                    }
                    
                    lightbox.style.display = 'block';
                };
                tempImg.src = this.src;
                
                // Reset zoom e posição
                currentScale = 1;
                moveX = 0;
                moveY = 0;
                updateImgTransform();
            });
        });
        
        // Fecha o lightbox
        document.querySelector('.close-btn').addEventListener('click', function() {
            lightbox.style.display = 'none';
        });
        
        // Zoom in
        document.querySelector('.zoom-in').addEventListener('click', function() {
            if (currentScale < 3) {
                currentScale += 0.5;
                updateImgTransform();
            }
        });
        
        // Zoom out
        document.querySelector('.zoom-out').addEventListener('click', function() {
            if (currentScale > 1) {
                currentScale -= 0.5;
                updateImgTransform();
            }
        });
        
        // Touch events para movimentação da imagem
        imageContainer.addEventListener('touchstart', function(e) {
            const touch = e.touches[0];
            startX = touch.clientX - moveX;
            startY = touch.clientY - moveY;
        });
        
        imageContainer.addEventListener('touchmove', function(e) {
            if (currentScale > 1) {
                e.preventDefault();
                const touch = e.touches[0];
                moveX = touch.clientX - startX;
                moveY = touch.clientY - startY;
                updateImgTransform();
            }
        });
        
        // Função para atualizar a transformação da imagem
        function updateImgTransform() {
            imageContainer.style.transform = `translate(-50%, -50%) scale(${currentScale}) translate(${moveX/currentScale}px, ${moveY/currentScale}px)`;
        }
        
        // Double tap para zoom
        let lastTap = 0;
        imageContainer.addEventListener('touchend', function(e) {
            const currentTime = new Date().getTime();
            const tapLength = currentTime - lastTap;
            
            if (tapLength < 300 && tapLength > 0) {
                if (currentScale === 1) {
                    currentScale = 2;
                } else {
                    currentScale = 1;
                    moveX = 0;
                    moveY = 0;
                }
                updateImgTransform();
            }
            lastTap = currentTime;
        });
    }
});