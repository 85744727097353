const scope = document.querySelector('header')
const btnOpen = scope.querySelectorAll('.btn-menu')
const btnOpenSubmenu = scope.querySelectorAll('.menu-item-has-children')

btnOpen.forEach(button => {
  button.addEventListener('click', () => {
      document.body.classList.toggle('-open-menu');
  });
});

btnOpenSubmenu.forEach(button => {
  button.addEventListener('click', (e) => {
    e.preventDefault();
    button.classList.toggle('-open-submenu');
  });
});
