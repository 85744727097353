document.addEventListener('DOMContentLoaded', function() {
  const videoItems = document.querySelectorAll('.video-item');

  videoItems.forEach(item => {
      const videoUrlDiv = item.querySelector('.video-url');
      const url = videoUrlDiv.dataset.url;
      const videoId = extractVideoId(url);

      if (videoId) {
          // Cria a URL da thumbnail
          const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

          // Cria o elemento de imagem e define o src para a thumbnail
          const thumbnailImg = document.createElement('img');
          thumbnailImg.src = thumbnailUrl;
          thumbnailImg.alt = 'YouTube video thumbnail';
          thumbnailImg.style.cursor = 'pointer';
          thumbnailImg.style.width = '100%'; // Ajusta o tamanho da thumbnail
          thumbnailImg.style.maxHeight = '294px'; // Limita a altura máxima

          // Insere a imagem da thumbnail logo abaixo da div .video-url
          videoUrlDiv.insertAdjacentElement('afterend', thumbnailImg);

          // Adiciona um evento de clique à thumbnail para trocar para o iframe
          thumbnailImg.addEventListener('click', function() {
              const iframe = document.createElement('iframe');
              iframe.width = "100%";
              iframe.height = "294";
              iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&color=white&modestbranding=1&rel=0&controls=1&showinfo=0&iv_load_policy=3`;
              iframe.frameBorder = "0";
              iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
              iframe.allowFullscreen = true;

              // Substitui a thumbnail pelo iframe ao clicar
              item.classList.add('--show')
              thumbnailImg.replaceWith(iframe);
              videoUrlDiv.remove()
          });
      }
  });

  function extractVideoId(url) {
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const matches = url.match(regex);
      return matches ? matches[1] : null;
  }
});
