document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.membro-item').forEach(function(membroItem) {
      const descricaoContainer = membroItem.querySelector('.membro-descricao-container');
      const descricao = membroItem.querySelector('.membro-descricao');
      const leiaMaisBtn = membroItem.querySelector('.leia-mais');

      // Força o browser a calcular o tamanho real da altura (incluindo o conteúdo escondido)
      const descricaoAlturaCompleta = descricao.scrollHeight;

      // Se a altura real for maior que a altura visível, mostra o botão "Leia mais"
      if (descricaoAlturaCompleta > descricaoContainer.clientHeight) {
          leiaMaisBtn.style.display = 'inline-block';
      }

      // Expande/contrai a descrição ao clicar em "Leia mais"
      leiaMaisBtn.addEventListener('click', function() {
          membroItem.classList.toggle('expanded');
          this.textContent = membroItem.classList.contains('expanded') ? 'Leia menos' : 'Leia mais';
      });
  });
});
