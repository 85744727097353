document.addEventListener('DOMContentLoaded', function() {
  const scope = document.querySelector('#page-publi');
  if (scope) {
    
    const filterItems = scope.querySelectorAll('.filter-item');
    const publicacoesSections = scope.querySelectorAll('.publicacoes-list');
    const clearSearchButton = scope.querySelector('.clear-search-button');
    const qtdAll = scope.querySelector('#qtd-publi');
    let publicacoesMap = {};
  
    // Inicialmente esconde o contador de resultados
    qtdAll.style.display = 'none';

    // Pré-processa as publicações e mapeia-as por slug e por seção
    publicacoesSections.forEach(section => {
      const items = section.querySelectorAll('.publicacao-item');
      const sectionSlug = section.id.replace('publicacoes-list-', '');
  
      items.forEach(elm => {
        const slug = elm.dataset.frente;
        if (!publicacoesMap[sectionSlug]) {
          publicacoesMap[sectionSlug] = {};
        }
        if (!publicacoesMap[sectionSlug][slug]) {
          publicacoesMap[sectionSlug][slug] = [];
        }
        publicacoesMap[sectionSlug][slug].push(elm);
      });
    });
  
    let activeFilters = new Set();
  
    filterItems.forEach(item => {
      item.addEventListener('click', (e) => {
        const slug = e.target.dataset.slug;
  
        if (slug === 'all') {
          activeFilters.clear();
          filterItems.forEach(item => item.classList.remove('--active'));
          activeFilters.add(slug);
          e.target.classList.add('--active');
        } else {
          const allFilter = document.querySelector('.filter-all');
          allFilter.classList.remove('--active');
          activeFilters.delete('all');
  
          if (activeFilters.has(slug)) {
            activeFilters.delete(slug);
            e.target.classList.remove('--active');
          } else {
            activeFilters.add(slug);
            e.target.classList.add('--active');
          }
        }
  
        // Verifica se nenhum filtro está ativo e ativa "Todos"
        const noActiveFilters = [...filterItems].filter(item => item.dataset.slug !== 'all').every(item => !item.classList.contains('--active'));
  
        if (noActiveFilters) {
          activeFilters.clear();
          activeFilters.add('all');
          document.querySelector('.filter-all').classList.add('--active');
        }
  
        publicacoesSections.forEach(section => {
          const sectionSlug = section.id.replace('publicacoes-list-', '');
          section.innerHTML = "";
  
          let filteredItems = [];

          if (activeFilters.size === 0 || activeFilters.has('all')) {
            for (let slug in publicacoesMap[sectionSlug]) {
              publicacoesMap[sectionSlug][slug].forEach(elm => {
                section.appendChild(elm);
                filteredItems.push(elm);
              });
            }
          } else {
            activeFilters.forEach(slug => {
              if (publicacoesMap[sectionSlug][slug]) {
                publicacoesMap[sectionSlug][slug].forEach(elm => {
                  section.appendChild(elm);
                  filteredItems.push(elm);
                });
              }
            });
          }

          if (filteredItems.length > 0 && filteredItems.length < section.querySelectorAll('.publicacao-item').length) {
            qtdAll.style.display = 'block';
            qtdAll.textContent = `${filteredItems.length} resultados encontrado`;
          } else {
            qtdAll.style.display = 'none';
          }
        });
      });
    });
  
    const searchInput = document.getElementById('publicacao-search-input');
    const searchButton = document.querySelector('.publicacao-search button');
  
    function searchPublications() {
      const query = searchInput.value.toLowerCase();
      const activeSection = document.querySelector('.content-page.--active');
      const activeSectionSlug = activeSection ? activeSection.querySelector('.publicacoes-list').id.replace('publicacoes-list-', '') : null;
  
      if (activeSectionSlug) {
        const items = activeSection.querySelectorAll('.publicacao-item');
        let visibleItems = 0;

        items.forEach(item => {
          const title = item.querySelector('.publicacao-title').textContent.toLowerCase();
          if (title.includes(query)) {
            item.style.display = 'block';
            visibleItems++;
          } else {
            item.style.display = 'none';
          }
        });

        if (visibleItems > 0 && visibleItems < items.length) {
          qtdAll.style.display = 'block';
          qtdAll.textContent = `${visibleItems} resultados encontrado`;
        } else {
          // qtdAll.style.display = 'none';
          qtdAll.textContent = `Nenhum resultado foi encontrado`;
        }
      }
    }
  
    searchButton.addEventListener('click', searchPublications);
  
    searchInput.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        searchPublications();
      }
    });
  
    clearSearchButton.addEventListener('click', function() {
      searchInput.value = '';
      const activeSection = document.querySelector('.content-page.--active');
      const activeSectionSlug = activeSection ? activeSection.querySelector('.publicacoes-list').id.replace('publicacoes-list-', '') : null;
  
      if (activeSectionSlug) {
        const items = publicacoesMap[activeSectionSlug];
        
        Object.keys(items).forEach(slug => {
          items[slug].forEach(item => {
            item.style.display = 'block';
          });
        });
      }

      // Oculta o contador de resultados ao limpar a busca
      qtdAll.style.display = 'none';
    });
  }
});
