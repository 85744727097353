document.addEventListener('DOMContentLoaded', function() {
  const btnOrder = document.querySelector('.open-orders');
  const orderContent = document.querySelector('.order-content');
  const orderItems = document.querySelectorAll('.order');
  let isOrderOpen = false;

  // Função para converter a data no formato "19 de setembro de 2024" para um objeto Date
  function parseDate(dateString) {
      // Suporta tanto o formato "19 de setembro de 2024" quanto datas ISO
      const regexPtBr = /(\d{1,2}) de (\w+) de (\d{4})/;
      const meses = {
          'janeiro': 0, 'fevereiro': 1, 'março': 2, 'abril': 3,
          'maio': 4, 'junho': 5, 'julho': 6, 'agosto': 7,
          'setembro': 8, 'outubro': 9, 'novembro': 10, 'dezembro': 11
      };

      // Tenta primeiro o formato brasileiro
      const match = dateString.match(regexPtBr);
      if (match) {
          const dia = parseInt(match[1], 10);
          const mes = meses[match[2].toLowerCase()];
          const ano = parseInt(match[3], 10);

          if (!isNaN(dia) && !isNaN(mes) && !isNaN(ano)) {
              return new Date(ano, mes, dia);
          }
      }

      // Se não for formato brasileiro, tenta como data ISO
      const isoDate = new Date(dateString);
      if (!isNaN(isoDate.getTime())) {
          return isoDate;
      }

      return null;
  }

  // Função genérica de ordenação
  function ordenarItems(itemsArr, orderType, titleSelector, dateSelector) {
      return itemsArr.sort((a, b) => {
          const titleA = a.querySelector(titleSelector)?.textContent.trim().toLowerCase() || '';
          const titleB = b.querySelector(titleSelector)?.textContent.trim().toLowerCase() || '';
          
          const dateElementA = a.querySelector(dateSelector);
          const dateElementB = b.querySelector(dateSelector);
          
          const dateA = dateElementA ? parseDate(dateElementA.textContent.trim()) : null;
          const dateB = dateElementB ? parseDate(dateElementB.textContent.trim()) : null;

          // Log para debug
          // console.log('Ordenando:', {
          //     titleA, titleB,
          //     dateA: dateA?.toISOString(),
          //     dateB: dateB?.toISOString(),
          //     orderType
          // });

          // Trata casos de elementos faltantes
          if (orderType.includes('_recentes')) {
              if (!dateA && !dateB) return 0;
              if (!dateA) return 1;
              if (!dateB) return -1;
              return orderType === 'mais_recentes' ? dateB - dateA : dateA - dateB;
          } else {
              return orderType === 'a_z' ? 
                  titleA.localeCompare(titleB) : 
                  titleB.localeCompare(titleA);
          }
      });
  }

  // Função genérica para gerenciar a ordenação
  function handleOrdering(listSelector, itemSelector, titleSelector, dateSelector) {
      const list = document.querySelector(listSelector);
      if (!list) return; // Retorna se a lista não existir

      const items = document.querySelectorAll(itemSelector);
      let itemsArr = Array.from(items); // Converte NodeList para array

      orderItems.forEach(item => {
          item.addEventListener('click', () => {
              const orderType = item.dataset.order;
              const sortedItems = ordenarItems(itemsArr, orderType, titleSelector, dateSelector);

              // Fecha o menu de ordenação
              orderContent.classList.remove('--active');
              isOrderOpen = false;

              // Limpa a lista e adiciona os itens ordenados
              list.innerHTML = '';
              sortedItems.forEach(el => list.appendChild(el));
          });
      });
  }

  // Inicializa a ordenação para cada caso específico
  handleOrdering('#comunicados-list', '.comunicado-item', '.comunicado-title', '.comunicado-date');
  // handleOrdering('#publicacoes-list', '.publicacao-item', '.publicacao-title', '.publicacao-date');
  handleOrdering('#recent-posts', '.post', '.post-title', '.post-date');

  // Evento de clique para abrir/fechar o menu de ordenação
  if (btnOrder) {
      btnOrder.addEventListener('click', (event) => {
          event.stopPropagation(); // Previne a propagação do evento
          orderContent.classList.toggle('--active');
          isOrderOpen = !isOrderOpen;
      });
  }

  // Evento de clique para fechar o menu de ordenação ao clicar fora
  document.addEventListener('click', (event) => {
      if (isOrderOpen && !orderContent.contains(event.target) && !btnOrder.contains(event.target)) {
          orderContent.classList.remove('--active');
          isOrderOpen = false;
      }
  });
});